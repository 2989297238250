import React, {createContext, useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import {defaultLanguage, getCurrentLanguage, languages} from "../lib/language";

const SettingsContext = createContext({
    wrapByDefault: true,
    enableTransition: true,
})

const SettingsProvider = ({children, wrap = true, transition = true}) => {
    const [settings, setSettings] = useState([])



    const data = useStaticQuery(graphql`
        {
            settings: allSanitySiteSettings {
                edges {
                    node {
                      language
                      title
                      description
                      _rawFooter(resolveReferences: {maxDepth: 1})
                      keywords
                      author {
                        name
                      }
                    }
                }
            },
        }
    `).settings.edges.map(n => n.node);

    useEffect(() => {

        let currentSettings = null;
        if(data.length) {
            currentSettings = data.find(s => s.language === getCurrentLanguage())
        }

        setSettings(currentSettings);
    }, []);

    return (
        <SettingsContext.Provider
            value={{
                settings, setSettings
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}
export {SettingsContext, SettingsProvider}
