import React, {useContext} from 'react'
import Container from "./Container";
import Logo from "./Logo";
import {Link} from "gatsby";
import NewsletterForm from "../forms/NewsletterForm";
import FooterCircle from "../assets/footer_circle.svg";
import {translate} from "../locale";
import {defaultLanguage, getCurrentLanguage} from "../lib/language";
import {SettingsContext} from "../context/SettingsContext";
import PortableText from "./PortableText";

const Footer = ({className, language}) => {
    const {settings} = useContext(SettingsContext);

    if (!language) {
        language = getCurrentLanguage();
    }
    return (
        <>
            <footer className={`bg-brand-body text-brand-grey-light text-sm ${className}`}>
                <Container className='relative pt-8 sm:pt-20 pb-10 sm:pb-20 '>
                    <div className="-mx-4 flex flex-row flex-wrap sm:justify-between relative z-50">
                        <div className="px-4 w-1/4 sm:w-auto sm:w-48">
                            <Logo white small />
                        </div>

                        <div className="px-4 w-2/4 sm:w-auto text-center sm:text-left">
                            {settings && settings._rawFooter ? (
                                <div className='footer prose text-white -mt-6'>
                                    {settings._rawFooter && <PortableText blocks={settings._rawFooter} />}
                                </div>
                            ) : (
                                <>
                                    <address className='not-italic text-sm mb-4'>
                                        Strandgata 6<br />
                                        6300 Åndalsnes
                                    </address>

                                    <div className='text-sm'>
                                        Tlf: <a href='tel:+41143020'>411 43 020</a>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="px-4 w-full sm:max-w-sm flex flex-col mt-8 sm:mt-0" />

                        <div
                            className="px-4 flex items-center mb-auto -mt-1 w-full sm:w-auto justify-center sm:justify-start">
                            <div className='mr-2'>
                                {translate('follow_us', language)}
                            </div>
                            <ul className='list-reset flex items-center'>
                                <li className='mr-2 inline-flex'>
                                    <a href='https://www.facebook.com/teamisi' target='_blank'
                                       className='bg-brand-grey-lighter text-white flex items-center justify-center h-8 w-8 rounded-full inline-flex transition duration-500 ease-in-out hover:bg-brand-blue'>
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li className='inline-flex'>
                                    <a href='https://www.linkedin.com/company/isi-as'
                                       target='_blank'
                                       className='bg-brand-grey-lighter text-white flex items-center justify-center h-8 w-8 rounded-full inline-flex transition duration-500 ease-in-out hover:bg-brand-blue'>
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        className="w-full justify-center sm:justify-end flex py-4 mb-8 sm:mb-0 relative z-50">
                        <div>
                            &copy; {new Date().getFullYear()} iSi AS
                        </div>
                        <Link className='ml-1 text-brand-grey-lighter hover:text-white'
                              to={`/${translate('privacy_url', language)}`}>
                            {translate('privacy_statement', language)}
                        </Link>
                    </div>
                    <div
                        className='absolute left-0 sm:left-auto bottom-0 right-0 flex flex-col justify-end items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="814.232" height="223.468"
                             viewBox="0 0 814.232 223.468" className='w-full h-auto sm:max-w-full max-w-xs'>
                            <path className="text-brand-grey-blend fill-current"
                                  d="M1085.025-1588.5q30.228-58.319,80.245-104.846,126.342-117.643,323.811-117.622,219.97,0,408.684,104.685l-51.584,98.238A202.955,202.955,0,0,1,1834.5-1588.5Z"
                                  transform="translate(-1084.203 1811.468)" />
                        </svg>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Footer;
