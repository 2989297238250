import React from 'react'
import Logo from './Logo'
import DecalThree from "../assets/decal3.svg";

const FeatureBox = ({content = {}, node = {}}) => {
    if (!content || (typeof content === 'object' && !content.hasOwnProperty('title'))) {
        content = node;
        if (!content || (typeof content === 'object' && !content.hasOwnProperty('title'))) {
            return (
                <div></div>
            );
        }
    }
    let backgrounds = [
        (<Logo xlarge className='mix-blend-mode absolute left-0 top-0 -ml-10 -mt-16' />),
        (<div className='w-12 absolute bottom-0 right-0 h-full'>
            <img src={DecalThree} className='mix-blend-mode w-full' />
        </div>)
    ]

    return (
        <div
            className='w-full rounded-3xl bg-brand-blue text-white p-10 md:mb-8 relative overflow-hidden'
            style={{minHeight: '310px'}}>
            {backgrounds[1]}
            <h2 className='mb-0 pt-0 font-heading text-2xl leading-snug font-semibold mb-20px text-lg text-white'>{content.title}</h2>
            <ul className='list-disc list-inside text-white'>
                {content.hasOwnProperty('features') && content.features.map((item, index) => (
                    <li className='flex leading-snug mb-10px' key={'feature' + index}>
                        <span className='text-4xl leading-3 mr-2 mt-1'>&middot;</span>
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default FeatureBox;
